export const GET_TASKLIST_START = 'GET_TASKLIST_START';
export const GET_TASKLIST_SUCCEED = 'GET_TASKLIST_SUCCEED';
export const GET_TASKLIST_FAIL = 'GET_TASKLIST_FAIL';

export const INSERT_AND_GET_TASKLIST_START = 'INSERT_AND_GET_TASKLIST_START';
export const INSERT_AND_GET_TASKLIST_SUCCEED =
  'INSERT_AND_GET_TASKLIST_SUCCEED';
export const INSERT_AND_GET_TASKLIST_FAIL = 'INSERT_AND_GET_TASKLIST_FAIL';

export const PATCH_TASK_START = 'PATCH_TASK_START';
export const PATCH_TASK_SUCCEED = 'PATCH_TASK_SUCCEED';
export const PATCH_TASK_FAIL = 'PATCH_TASK_FAIL';

export const DELETE_TASK_START = 'DELETE_TASK_START';
export const DELETE_TASK_SUCCEED = 'DELETE_TASK_SUCCEED';
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';

export const MOVE_TASK_START = 'MOVE_TASK_START';
export const MOVE_TASK_SUCCEED = 'MOVE_TASK_SUCCEED';
export const MOVE_TASK_FAIL = 'MOVE_TASK_FAIL';

export const CLEAR_AND_GET_TASK_LIST_START = 'CLEAR_AND_GET_TASK_LIST_START';
export const CLEAR_AND_GET_TASK_LIST_SUCCEED =
  'CLEAR_AND_GET_TASK_LIST_SUCCEED';
export const CLEAR_AND_GET_TASK_LIST_FAIL = 'CLEAR_AND_GET_TASK_LIST_FAIL';
